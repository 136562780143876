exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apartment-1-js": () => import("./../../../src/pages/apartment_1.js" /* webpackChunkName: "component---src-pages-apartment-1-js" */),
  "component---src-pages-apartment-2-js": () => import("./../../../src/pages/apartment_2.js" /* webpackChunkName: "component---src-pages-apartment-2-js" */),
  "component---src-pages-apartment-js": () => import("./../../../src/pages/apartment.js" /* webpackChunkName: "component---src-pages-apartment-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */)
}

